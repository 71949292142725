import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import Main from './main';

export default function Layout({ children }) {
    return (
        <>
            <Box>
                <Main>{children}</Main>
            </Box>
        </>
    );
}

Layout.prototype = {
    children: PropTypes.node,
};
