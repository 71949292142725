import { StatusProvider } from './contexts/statusContext';
import './global.css';
import { useScrollToTop } from './hooks/use-scroll-to-top';
import Router from './routes/sections';
import ThemeProvider from './theme';

// ----------------------------------------------------------------------

export default function App() {
    useScrollToTop();

    return (
        <StatusProvider>
            <ThemeProvider>
                <Router />
            </ThemeProvider>
        </StatusProvider>
    );
}
