import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import PrivateRoute from './privateRoute';

export const IndexPage = lazy(() => import('../pages/app'));
export const DateLists = lazy(() => import('../pages/date-lists'));
export const RealDateLists = lazy(() => import('../pages/real-date-lists'));
export const ReservationLists = lazy(() =>
    import('../pages/reservation-lists')
);
export const AutoLogin = lazy(() => import('../components/autoLogin'));

// ----------------------------------------------------------------------

export default function Router() {
    const routes = useRoutes([
        {
            path: '/',
            element: <IndexPage />,
            index: true,
        },
        {
            path: 'autologin',
            element: <AutoLogin />,
        },
        {
            path: '/discountbooking',
            element: <PrivateRoute />,
            children: [
                {
                    index: true,
                    element: <Navigate to="datelists" />,
                },
                {
                    path: 'datelists',
                    element: <DateLists />,
                },
                {
                    path: 'reservationlists',
                    element: <ReservationLists />,
                },
            ],
        },
        {
            path: 'booking/datelists',
            element: <RealDateLists />,
        },

        // {
        //   path: 'login',
        //   element: <LoginPage />,
        // },
        // {
        //   path: '404',
        //   element: <Page404 />,
        // },
        // {
        //   path: '*',
        //   element: <Navigate to="/404" replace />,
        // },
    ]);

    return routes;
}
